<template>
  <div class="page1">
    <!--停车记录查询-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item label="城市">
            <el-select
              v-model.trim="formInline.areaId"
              @change="areaChange"
              filterable
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable>
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属区域" prop="areaId">
                <a-cascader
                  @change="handleAreaChange"
                  ref="cascader"
                ></a-cascader>
                <!-- <el-select
              v-model.trim="formInline.streetId"
              filterable
              @change="
                $refs.parkInput.setValue('');
                formInline.operationId = '';
              "
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList2"
              ></el-option>
            </el-select> -->
              </el-form-item>

              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Channel_name')">
                <el-autocomplete
                  :disabled="formInline.channelDisabled"
                  v-model="formInline.entryName"
                  :fetch-suggestions="querySearchAsyncEntry"
                  value-key="parkChannelName"
                  placeholder="请输入内容"
                  @select="handleSelectEntry"
                  @input="changeEntryName"
                ></el-autocomplete>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
            <el-select v-model.trim="formInline.parkType" filterable placeholder="" size="10">
              <el-option label="全部" value="-1"></el-option>
              <el-option
                :label="park.desc"
                :value="park.code"
                :key="park.code"
                v-for="park in parkType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="mobile" ref="mobile">
            <el-input
              size="12"
              :maxlength="11"
              v-model="formInline.mobile"
              placeholder="手机号"
            ></el-input>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
                <el-autocomplete
                  ref="plateNumber"
                  size="11"
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="车牌号"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Operator')">
                <el-autocomplete
                  class="inline-input"
                  v-model="operatorName"
                  :fetch-suggestions="querySearchOperatorName"
                  placeholder="请输入内容"
                  value-key="centralizeManagerName"
                  :trigger-on-focus="false"
                  @select="handleSelectOperatorName"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Event_type')">
                <el-select
                  v-model.trim="formInline.eventType"
                  filterable
                  size="8"
                >
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in eventTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Processing_results')">
                <el-select v-model.trim="formInline.result" filterable size="8">
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in ResultTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="time_item">
                <el-select v-model="formInline.timeType">
                  <el-option :label="$t('searchModule.Report_time')" :value="1"></el-option>
                  <el-option :label="$t('searchModule.processing_time')" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <!--<el-form-item label="是否查询临时停车" >
          	<el-select v-model.trim="formInline.parkType" filterable placeholder="" size="10">
	            <el-option label="全部" value="-1"></el-option>
	            <el-option :label="park.desc" :value="park.code" :key="park.code" v-for="park in parkType"></el-option>
            </el-select>
        </el-form-item>-->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm()"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
          <!-- <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="exportFile()"
            >{{ $t('button.export') }}</el-button
          > -->

          <!-- <p style="color: #909399">说明：导出数据最多一次导出10,000条，如果筛选条件范围较大，则只能导出前10,000条。</p> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF">
        <el-table
          border
          stripe
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 弹窗 -->
      <pic-detail
        :infoDetail="parkDetail"
        :detailFlag="dialogVisible"
        @close="dialogVisible = false"
        :entryPic="entryPic"
        :exitPic="exitPic"
      ></pic-detail>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import timeRangePick from "@/components/timePicker";
import picDetail from "./parkComponent.vue";
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponentRoadside";
import InfoList from "@/components/infoList/infoList";
import exportFile from "@/components/exportFile/exportFile.vue";
export default {
  name: "businessParkRecord",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      exportData: {},
      defalutDate: [],
      flag: false,
      plateNumber: "",
      rowData: {},
      infoSize: 4,
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      operatorName: "",
      entryPic: [],
      exitPic: [],
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      // 事件类型共有6种需要记录（实际业务使用4种）入场流水、入场通过、出场流水、出场通过、入场折返、出场折返
      // 事件类型: 1=入场流水，2=入场通过，3=出场流水，4=出场通过，5=入场折返，6=出场折返
      eventTypeList: [
        { code: "", desc: "全部" },
        // { code: 1, desc: "入场流水" },
        // { code: 3, desc: "出场流水" },
        { code: 2, desc: "入场通过" },
        { code: 4, desc: "出场通过" },
        { code: 5, desc: "入场折返" },
        { code: 6, desc: "出场折返" },
      ],
      ResultTypeList: [
        { code: "", desc: "全部" },
        { code: 1, desc: "一致" },
        { code: -1, desc: "不一致" },
      ],
      roleList: [],
      tenantList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.Belonging_region"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },

        {
          prop: "confirmedPlateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (
              row.confirmedPlateNumber &&
              row.confirmedPlateNumber.indexOf("无") != -1
            ) {
              return "无牌车";
            } else {
              return row.confirmedPlateColor + ":" + row.confirmedPlateNumber;
            }
          },
        },
        {
          prop: "plateNumber",
          label: "原始车牌号",
          width: "",
          formatter: (row, column) => {
            if (row.carId == row.confirmedCarId) {
              return "";
            } else if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateColor + ":" + row.plateNumber;
            }
          },
        },
        {
          prop: "eventTypeDesc",
          label: "事件类型",
          width: "",
        },
        {
          prop: "uploadTime",
          label: this.$t("list.Report_time"),
          width: "",
        },
        {
          prop: "confirmedTime",
          label: this.$t("list.processing_time"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "oprNum",
          label: "流水ID",
          width: "",
        },
        {
          prop: "parkChannelName",
          label: this.$t("list.Channel_name"),
          width: "",
        },
        {
          prop: "resultDesc",
          label: "处理结果",
          width: "",
        },
      ],
      tableData: [],
      parkDetail: [],
      parkRecordId: 0,
      page: 1,
      size: 15,
      formInline: {
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryName: "",
        operatorId: "",
        eventType: "",
        result: "",
        startUploadTime: "",
        endUploadTime: "",
        mobile: "",
        timeType: 1,
        name: "智能处理记录",
        parkChannelId: "",
        parkChannelName: "",
        channelDisabled: true,
      },
      areaIds: "",
    };
  },
  watch: {
    operatorName(newVal) {
      if (!newVal) {
        this.formInline.operatorId = "";
      }
    },
  },
  methods: {
    // 时间戳转换
    getTime(times) {
      const stamp = new Date(times);
      const time = Moment(stamp).format("YYYY-MM-DD HH:mm:ss");
      return time;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startUploadTime = timeArr[0];
      this.formInline.endUploadTime = timeArr[1];
    },
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.formInline = {
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        operatorId: "",
        entryName: "",
        eventType: "",
        result: "",
        startUploadTime: "",
        endUploadTime: "",
        mobile: "",
        name: "智能处理记录",
        timeType: 1,
        parkChannelId: "",
        parkChannelName: "",
        channelDisabled: true,
      };
      this.formInline.parkName = "";
      this.operatorName = "";
      this.$refs.timeRangePicker.resetTime();
      this.$refs.parkInput.setValue();
      this.$refs.cascader.clear();
      this.areaIds = "";
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
        this.formInline.channelDisabled = false;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
        this.formInline.channelDisabled = true;
      }
      this.formInline.entryName = "";
      this.formInline.parkChannelId = "";
      this.formInline.parkChannelName = "";
    },
    plateNumberSelect(item) {},
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },
    // 选车牌
    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    querySearchOperatorName(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "acb/2.0/bacb/systems/loginUser/centralizeManager/combobox";
      this.$axios
        .get(url, {
          data: {
            name: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelectOperatorName(item) {
      this.formInline.operatorId = item.centralizeManagerId;
    },
    onTopClick() {
      window.location.hash = "#order";
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    detailShow(data) {
      this.dialogVisible = true;
      this.parkRecordId = data.cameraEventWorkOrderId;
      this.getRsType(data.cameraEventWorkOrderId);
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.formInline.operationId,
            areaIds: this.areaIds,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 获取车场类型 */
    // getPark() {
    //   let opt = {
    //     url: '/acb/2.0/parkType/list',
    //     method: 'get',
    //     data: {},
    //     success: (res) => {
    //       if (res.state == 0) {
    //         this.parkType = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 出入场类型列表

    // 获取停车场详情图片
    getPic(entryOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/bacb/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
              console.log(this.entryPic, " this.entryPic");
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    clearSearchOpt(data) {
      this.parkData();
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
      });
    },
    handleSelectEntry(item) {
      console.log(item, "ewww");
      this.formInline.parkChannelId = item.parkChannelId;
    },
    querySearchAsyncEntry(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getChannelName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            channelName: queryString,
            parkId: this.formInline.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value && res.value.length) {
              cb(res.value);
            } else {
              cb([]);
            }
          }
        });
    },
    changeEntryName() {
      if (this.formInline.entryName == "") {
        this.formInline.parkChannelId = "";
      }
    },
    showLog() {
      if (this.formInline.startUploadTime && this.formInline.endUploadTime) {
        let time =
          new Date(this.formInline.endUploadTime) -
          new Date(this.formInline.startUploadTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "acb/2.0/bacb/camera/event/work/order/getOrderRecordPageList",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          areaId: this.areaIds,
          parkId: this.formInline.parkId,
          operationId: this.formInline.operationId,
          eventType: this.formInline.eventType,
          operatorId: this.formInline.operatorId,
          result: this.formInline.result,
          // startUploadTime: this.formInline.startUploadTime,
          // endUploadTime: this.formInline.endUploadTime,
          carId: this.formInline.carId,
          dataSource: 3,
          parkChannelId: this.formInline.parkChannelId,
        },
        // data: {
        //   page: this.pageNum,
        //   pageSize: this.pageSize,
        //   cityId: this.formInline.areaId,
        //   areaId: this.formInline.streetId,
        //   operationId: this.formInline.operationId,
        //   parkId: this.formInline.parkId,
        //   carId: this.formInline.carId,
        //   inOutType: this.formInline.eventType,
        //   startUploadTime: this.formInline.startUploadTime,
        //   endUploadTime: this.formInline.endUploadTime,
        //   plateNumber: this.formInline.plateNumber,
        //   parkName: this.formInline.parkName
        // },
        success: (res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      if (this.formInline.timeType == 1) {
        opt.data.startUploadTime = this.formInline.startUploadTime;
        opt.data.endUploadTime = this.formInline.endUploadTime;
      } else {
        opt.data.confirmedTimeStart = this.formInline.startUploadTime;
        opt.data.confirmedTimeEnd = this.formInline.endUploadTime;
      }
      this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      // if (!this.formInline.carId) {
      //   this.formInline.plateNumber = ''
      //   this.$message.error('请输入并选择车牌')
      //   return
      // }
      let flag = this.showLog();
      if (!flag) return;
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          if (flag) {
            this.loading = true;
            this.flag = false;
            this.$refs.parkInput.setShowVal(this.formInline.parkName);
            this.searParkRecordListFun();
          }
        }
      });
    },
    // 停车记录详情
    getRsType(cameraEventWorkOrderId) {
      console.log();
      let opt = {
        url: "/acb/2.0/bacb/camera/event/work/order/getOrderDetailByOrderId/",
        data: { cameraEventWorkOrderId: cameraEventWorkOrderId },
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.oprNum);
            // this.getPic("553c1256-31a3-11ee-9975-c5b269cd4feb")
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    // 导出
    exportFile() {
      let opt = {};
      opt = {
        areaId: this.formInline.streetId,
        parkId: this.formInline.parkId,
        operationId: this.formInline.operationId,
        eventType: this.formInline.eventType,
        operatorId: this.formInline.operatorId,
        result: this.formInline.result,
        // startUploadTime: this.formInline.startUploadTime,
        // endUploadTime: this.formInline.endUploadTime,
        carId: this.formInline.carId,
        cityId: this.formInline.areaId,
        // plateNumber: this.formInline.plateNumber,
        dataSource: 3,
        parkChannelId: this.formInline.parkChannelId,
      };
      if (this.formInline.timeType == 1) {
        opt.startUploadTime = this.formInline.startUploadTime;
        opt.endUploadTime = this.formInline.endUploadTime;
      } else {
        opt.confirmedTimeStart = this.formInline.startUploadTime;
        opt.confirmedTimeEnd = this.formInline.endUploadTime;
      }
      exportExcelNew("/acb/2.0/bacb/camera/event/work/order/export", opt);
    },
  },
  components: {
    // InfoList,
    picDetail,
    myComponent,
    timeRangePick,
    exportFile,
  },

  /**
   * 现在是 停车场 名称 ， 商户名称用现在的   车牌号， 城市， 所属区域，出入场类型 支付方式  用以前的
   */
  created() {
    // this.parkData()
    this.getTenantList();
    this.getParkSpinner();
    if (this.$route.query.memberId) {
      //  this.formInline.startUploadTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.formInline.startUploadTime = this.$route.query.bindTime
        ? this.$route.query.bindTime
        : this.$route.query.createdTime
        ? this.$route.query.createdTime
        : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.formInline.endUploadTime = dateFormat(
        new Date(),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.date1 = this.formInline.startUploadTime;
      this.date2 = this.formInline.endUploadTime;
      if (this.$route.query.plateNumber) {
        this.plateNumber =
          this.$route.query.plateNumber.split(",").length > 1
            ? ""
            : this.$route.query.plateNumber;
      }
      this.formInline.plateNumber = this.plateNumber;
      this.formInline.mobile = this.$route.query.mobile;
      this.flag = true;
      this.searParkRecordListFun();
    }
    this.defalutDate = [
      this.dataTimeRest() + " 00:00:00",
      this.dataTimeRest() + " 23:59:59",
    ];
  },
  mounted() {
    this.searParkRecordList();
    this.exportData = {
      url: "/acb/2.0/bacb/camera/event/work/order/export",
      methods: "get",
      data: this.formInline,
      // data: {
      //   parkId: this.formInline.parkId,
      //   startTime: dateFormat(this.formInline.startTime),
      //   endTime: dateFormat(this.formInline.endTime),
      //   // openType: this.formInline.openType,
      //   operatorId: this.formInline.operatorId,
      //   name: `特殊放行记录`,
      // },
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.time_item {
  min-width: 0;

  .el-input__inner {
    // width: 72px;
  }
}

.breadcrumb {
  // height 35px
}

.conborder {
  border-top: 1px solid #C0CCDA;
  background: #FFFFFF;
  overflow: hidden;
  padding: 10px;
}

.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
